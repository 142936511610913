import React, { useState } from "react";
import "./styles.css";
import generateActivity from "./generateActivity";
import ruby from "./RubyNoBackground.png";
import huck from "./HuckNoBackground.png";
import shelly from "./ShellyNoBackground.png";
import shellyHappy from "./ShellyHappyNoBackground.png";

export default function App() {
  const activities = [
    "A pasta making class like https://nonnalive.com/",
    "Knitting a sweater",
    "Brewing beer",
    "Going to a hot yoga class",
    "Painting a picture",
    "Trying to make homemade pickles or something fermented",
    "Using Garageband to compose a song",
    "Making a cocktail tiktok/reel",
    "Writing a fictional/creative story",
    "Playing through 1 video game of husband’s choosing, I hear he has good taste",
    "Carving something out of wood",
    "Rock Climbing",
    "Pottery Class",
    "Climb to the top of a mountain",
    "Go snowshoeing ",
    "Photoshop the background out of one of our pictures and make it look like we are on the moon",
    "Volunteer somewhere for a day",
    "Dress the pets up in costumes. Huck wants to be a bumble bee",
    "Make those ravioli with the egg yolk inside",
    "Design a video game for husband to build",
    "Make donuts",
    "Learn some Arabic",
    "Build a house of cards bigger than 3 stories ",
    "Set up 1000 dominoes and then knock them down",
    "Groom a bonsai tree",
    "Tie rubber bands around a watermelon 'til it pops",
    "Invent your own signature spice mix",
    "Ride a bike",
    "Bake Finnish style rye bread",
    "Jump rope 100 times without messing up",
    "Fold a paper crane or any origami",
    "Fly a paper airplane as far as you can",
    "Take a tennis lesson",
    "Make a mixtape",
    "Get into photography",
    "Write a bucket list ",
    "Fly a kite",
    "Watch a sunset",
    "Swim in a lake",
    "Sit around a fire",
    "Groom huck (he made me put this here)",
    "Taco truck",
    "Learn a magic trick",
    "Learn to juggle",
    "Go on a run",
    "Go to a museum",
    "Click on Shelly",
    "Drive through Guardsmans pass",
    "Go get lunch at snowbird and eat outside",
    "Watch the godfather trilogy",
    "Do a squat on the balance board",
    "Watch an anime series",
    "Draw a comic book of your ideal superhero",
    "Learn about the different types of wine",
    "Make cheese",
    "Tai Chi",
    "Take Ruby to a stream and convince her to go in so she won’t smell so bad",
    "Invite your siblings over for a dinner party",
    "Marie Kondo a room",
    "Make a gift package to send your family",
    "Write a poem",
    "Learn how to do a rubiks cube",
    "See how long you can hula hoop",
    "Play a game of Sudoku",
    "Learn a party trick like making a recorder out of a carrot",
    "Learn how to do a cartwheel",
    "Make sausage",
    "Make candy",
    "Invent your own popsicle flavor",
    "Restore an antique"
  ];
  const [isShellyHappy, setIsShellyHappy] = useState(true);

  const [activity, setActivity] = useState(generateActivity(activities));

  return (
    <div className="App">
      <div className="textBox">
        <h1>Ideas of stuff to do!</h1>
        <h2>{activity}</h2>
      </div>
      <button onClick={() => setActivity(generateActivity(activities))}>
        Click for new idea!
      </button>
      <div className="slide-left">
        {isShellyHappy ? (
          <img
            src={shellyHappy}
            className="shelly"
            alt="our dog shelly looking happy"
            onClick={() => setIsShellyHappy(!isShellyHappy)}
          />
        ) : (
          <img
            src={shelly}
            className="shelly"
            alt="our dog shelly"
            onClick={() => setIsShellyHappy(!isShellyHappy)}
          />
        )}
      </div>
      <div className="slide-right">
        <img src={huck} className="huck" c alt="our cat huck" />
      </div>
      <div className="slide-up">
        <img src={ruby} className="ruby" c alt="our dog ruby" />
      </div>
    </div>
  );
}
